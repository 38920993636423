$is-codecombat: false

@import "app/styles/bootstrap/variables"
@import "app/styles/mixins"
@import "app/styles/style-flat-variables"
@import "app/styles/component_variables"

footer#site-footer

  background: url(/images/components/footer-bg.webp) bottom center
  .teal-theme &
    background: url(/images/components/footer-bg__teal.webp) bottom center
    background-repeat: repeat-x

  &.dark-mode
    background-color: #0C1016
    background-image: url(/images/components/footer-bg-dark.webp)

  overflow: hidden
  background-size: contain
  background-repeat: repeat-x

  .container
    border-radius: 8px
    background: var(--color-bg-gradient)
    margin-bottom: 80px
    margin-top: 300px

  &.dark-mode .container
    background: linear-gradient(100deg, #193640 0%, #021E27 100%)

  @media print
    display: none

  .small
    font-family: $body-font
    font-weight: normal
    font-size: 14px
    line-height: 19px
    letter-spacing: 0.58px

  .text-h3

    &:empty
      min-height: 1em

    margin: 20px auto
    display: block
    font-variant: normal
    color: var(--color-primary)
    font-family: $main-font-family
    @extend %font-14
    font-style: normal
    font-weight: 800
    line-height: 150%
    text-transform: uppercase
  &.dark-mode .text-h3
    color: #4DECF0

  li
    color: $dark-grey
    font-family: $main-font-family
    @extend %font-16
    font-style: normal
    font-weight: 400
    line-height: 150%
    margin: 16px 0
  &.dark-mode li
    color: white

  .col-lg-3
    padding-bottom: 15px
    @media (max-width: $screen-md-min)
      padding-left: 27px

    &.shrunken-empty-column
      margin-right: -12.5%

  @media (max-width: $screen-sm-min)
    height: auto

  a
    color: $dark-grey
  &.dark-mode a
    color: white

  .social-buttons > a
    margin-right: 10px

  .small
    color: rgba(255,255,255,0.8)

  .mpqr
    width: 95px

  .follow_us
    display: flex
    flex-direction: column
    .socialicon
      display: flex
      justify-content: space-between
      .si
        width: 50px
        height: 45px
        background-size: 50px
        background-position: center
        background-repeat: no-repeat
        position: relative
        cursor: pointer
      .si-bilibili
        background-image: url('https://assets.koudashijie.com/images/home/icon/bilibili-dark.png')
        &:hover
          background-image: url('https://assets.koudashijie.com/images/home/icon/bilibili-light.png')
      .si-wechat
        background-image: url('https://assets.koudashijie.com/images/home/icon/wechat-dark.png')
        &:hover
          background-image: url('https://assets.koudashijie.com/images/home/icon/wechat-light.png')
        &:hover .mpqrcode
          display: flex
      .si-tiktok
        background-image: url('https://assets.koudashijie.com/images/home/icon/tiktok-dark.png')
        &:hover
          background-image: url('https://assets.koudashijie.com/images/home/icon/tiktok-light.png')
        &:hover .tkqrcode
          display: flex
      .si-weibo
        background-image: url('https://assets.koudashijie.com/images/home/icon/weibo-dark.png')
        &:hover
          background-image: url('https://assets.koudashijie.com/images/home/icon/weibo-light.png')

    .tkqrcode
      display: none
      position: absolute
      top: 50px
      left: 0
      .tkqr
        width: 120px
    .mpqrcode
      display: none
      position: absolute
      top: 50px
      left: 0
      .span
        margin-right: 20px
        display: flex
        flex-direction: column
        align-items: center

  .footer-links
    &__row
      display: flex
      flex-wrap: wrap
      margin-left: -15px
      margin-right: -15px
      justify-content: space-between
    &__col
      padding-left: 15px
      padding-right: 15px
      max-width: 25%
      @media (max-width: $screen-sm-min)
        flex: 0 0 50%
        max-width: 50%
      @media (max-width: $screen-xs-min)
        flex: 0 0 100%
        max-width: 100%

  #final-footer
    padding: 20px 70px 14px
    font-size: 14px

    .float-right
      padding-top: 15px
      float: right

    @media (max-width: $screen-md-min)
      position: inherit
      height: auto
      .float-right
        float: none

    a
      color: rgba(255,255,255,0.8)
      margin-left: 20px

    img
      height: 40px
      margin-right: 20px

    img#mps
      height: 1em
      margin-right: 0

    .small
      color: rgba(255,255,255,0.8)

    .contact
      margin-right: 20px

  .hover-link
    cursor: pointer

footer#site-footer.dark-mode
  /*background-color: #0C1016*/

