$is-codecombat: false

@import "app/styles/bootstrap/variables"
@import "app/styles/mixins"
@import "app/styles/style-flat-variables"
@import "app/styles/component_variables"

#final-footer
  padding: 20px 70px 14px
  color: rgba($light-grey, 0.5)
  font-size: 14px

  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 20px
  text-align: center

  @media (max-width: $screen-md-min)
    position: inherit
    height: auto

  img
    height: 40px
    margin-right: 20px

  img#mps
    height: 1em
    margin-right: 0

  .contact
    margin-right: 20px

  .social-links
    .img
      height: 24px
      display: inline-block
      margin-right: 20px
      ::v-deep
        svg
          height: 100%
          path
            fill: var(--color-primary)
