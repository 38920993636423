$is-codecombat: false
@use "sass:math"
@import "app/styles/mixins"
@import "app/styles/bootstrap/variables"

@import "ozaria/site/styles/common/common.scss"
@if $is-codecombat

  #auth-modal

    //- Clear modal defaults

    .modal-dialog
      padding: 0
      width: 666px
      height: 582px


    //- Background

    .auth-modal-background
      position: absolute
      top: -90px
      left: -40px


    //- Header

    h1
      position: absolute
      left: 183px
      top: 0px
      margin: 0
      width: 255px
      text-align: center
      color: rgb(254,188,68)
      font-size: 32px
      text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0

      &.long-title
        top: -14px


    //- Close modal button

    #close-modal
      position: absolute
      left: 442px
      top: -15px
      width: 60px
      height: 60px
      color: white
      text-align: center
      font-size: 30px
      padding-top: 15px
      cursor: pointer
      @include rotate(-3deg)

      &:hover
        color: yellow


    //- Modal body content

    .auth-form-content
      position: absolute
      top: 100px
      left: 40px
      width: 588px

      .help-block
        margin: 0

      .alert
        margin-top: -25px
        margin-bottom: 0
        padding: 10px 15px

      #recover-account-wrapper
        float: right
        margin-top: 7px

      [dir="rtl"] #recover-account-wrapper
        float: left

      .israel-server-hint
        float: left
        margin-top: 7px

      .form-group
        color: rgb(51,51,51)
        padding: 0
        margin: 0

        .input-border
          border: 2px solid rgb(233, 221, 194)
          border-radius: 4px

          input
            background-color: rgb(239, 232, 216)
            border: 2px solid rgb(26, 21, 18)
            border-radius: 4px

      label
        font-size: 20px
        text-transform: uppercase
        font-family: $headings-font-family
        margin-bottom: 0

        .optional-note
          font-size: 14px

      //- Check boxes

      .form-group.checkbox
        margin: 10px 0

        label
          position: relative
          line-height: 34px

          span:not(.custom-checkbox)
            margin-left: 40px

        input
          display: none

          & + .custom-checkbox
            .glyphicon
              display: none

          &:checked + .custom-checkbox .glyphicon
            display: inline
            color: rgb(248,169,67)
            text-align: center
            text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black
            font-size: 20px
            position: relative
            top: -2px

        .input-border
          border-radius: 4px
          height: 34px
          width: 34px
          position: absolute

        .custom-checkbox
          border-radius: 4px
          position: absolute
          height: 30px
          width: 30px
          border: 2px solid rgb(26,21,18)
          background: rgb(228,217,196)
          text-align: center

      //- Primary auth button

      #login-btn
        position: absolute
        top: 160px
        height: 70px
        font-size: 32px
        line-height: 42px


    //- Footer area

    .auth-network-logins
      position: absolute

      #schoology-login-btn
        z-index: 100
        border: 1px solid black
        background-color: white
        color: black

      &.two-choices
        top: 364px
        width: 580px
        left: 48px
        padding-left: 29px

        .network-login
          width: 251px

          img
            width: 251px

      &.three-choices
        top: 350px
        left: -30px
        right: -30px
        display: flex
        justify-content: center
        align-items: center
        gap: 10px

      .network-login
        float: left
        text-align: center
        position: relative

        .network-logo
          height: 30px
          position: absolute
          left: -10px
          top: 2px

        .sign-in-blurb
          line-height: 34px

        #facebook-blurb
          margin-left: 12px

        .fb-login-button
          $scaleX: math.div(251, 64)
          $scaleY: math.div(60, 23)
          transform: scale($scaleX, $scaleY)
          position: absolute
          top: 4px
          left: 74px
          @include opacity(0.01)

        .gplus-login-wrapper
          position: absolute
          left: 65px
          top: -6px
          $scaleX: math.div(251, 84)
          $scaleY: math.div(60, 24)
          transform: scale($scaleX, $scaleY)
          @include opacity(0.01)

        .clever-login-img
          height: 43px

      #github-login-button
        position: relative
        top: -1px
        border-radius: 5px
        img
          width: 16px
          margin: 0 5px 0 -5px

      #gplus-login-button
        position: relative
        top: 8px


      .signup-with-button
        padding: 4px
        display: inline-flex
        gap: 10px
        justify-content: initial
        align-items: center
        border-radius: 5px
        height: 45px
        width: 130px        

      #schoology-login-btn
        border: 1px solid #117bbc
        color: black
        background-color: white
        img
          width: 38px
          height: 38px
          border-radius: 50%
      #facebook-login-btn
        border: 1px solid #3b5998
        color: white
        background-color: #3b5998
        img
          width: 38px
          height: 38px
          border-radius: 50%


    //- Extra bottom pane area
    .extra-pane
      background-image: url(/images/pages/modal/auth/extra-pane.png)
      width: 633px
      height: 139px
      padding: 23px 23px 23px 168px
      position: absolute
      top: 408px

      .switch-explanation
        margin: 25px 10px 0 0
        width: 200px
        color: rgb(254,188,68)
        font-size: 20px
        font-family: $headings-font-family
        font-weight: bold
        text-transform: uppercase
        text-shadow: black 1px 1px 0, black -1px -1px 0, black 1px -1px 0, black -1px 1px 0, black 1px 0px 0, black 0px -1px 0, black -1px 0px 0, black 0px 1px 0
        float: left

      .btn
        float: right
        margin-top: 20px
        width: 230px
        height: 70px
        line-height: 40px


  .ie10 #auth-modal .auth-network-logins .btn.btn-lg.network-login .network-logo, .lt-ie10 #auth-modal .auth-network-logins .btn.btn-lg.network-login .network-logo
    left: 15px
    top: 15px

  body[lang^='pt'], body[lang^='es'], body[lang='fr'], body[lang^='de'], body[lang='hu'], body[lang='pl'], body[lang='sr'], body[lang='sk']
    #auth-modal .auth-network-logins .btn.btn-lg.network-login
      font-size: 16px

@else

  #auth-modal
    display: flex
    justify-content: center
    align-items: center

    h2
      color: $pitch
      text-align: center
      padding-bottom: 20px


    p
      color: $pitch


    input.form-control
      border: 1px solid $teal
      color: $color-tertiary-brand


    #login-btn, .btn.btn-primary.btn-lg
      background-image: unset
      background-color: $teal
      border: unset
      text-shadow: unset
      font-family: "Open Sans", sans-serif
      font-size: 18px
      font-weight: bold
      letter-spacing: 0.71px
      line-height: 24px
      color: $mist
      min-height: 45px
      min-width: 182px
      border-radius: 1px


    .row.or-row

      display: flex
      justify-content: center
      align-items: center

      margin-bottom: 15px

      p.or
        color: $authGray
        margin: 0


      .line
        height: 2px
        width: 120px
        background-color: #f0f0f0
        margin: 0 32px



    .modal-content
      background-color: white
      padding: 40px 36px 20px


    .auth-network-logins
      display: flex
      justify-content: center
      margin: 12px 0 30px
      button, a
        margin: 0 13px
        min-width: 180px
        min-height: 45px



    .extra-pane > p:nth-child(2)
      margin: 12px 0 0


    .extra-pane
      text-align: center
      margin-top: 26px

      a
        color: $authGray
        text-decoration: underline


      p
        text-align: center
        font-size: 14px
        color: $authGray



    #recover-account-wrapper
      text-align: right

      a
        color: $authGray
        text-decoration: underline



    #gplus-login-btn > img, #clever-login-btn > img
      height: 46px



  #login-btn
    max-width: 180px
    margin: 0 auto